<template>
	<main class="auth-main">

		<div class="absolute left-0 top-0 h-16 px-5 flex items-center">
			<router-link to="/">
				<h1>DinAnmälan</h1>
			</router-link>
		</div>

		<div class="auth">
			<div class="auth-left"></div>


			<div class="auth-right">
				<h2 class="mb-20">Återställ ditt lösenord</h2>

				<form @submit.prevent="send">
					<BaseInput type="email" v-model="email" class="auth-input">
						E-postadress
					</BaseInput>

					<BaseLinkButton to="/login" class="auth-submit mr-5" transparent>
						Tillbaka
					</BaseLinkButton>

					<BaseButton type="submit" class="auth-submit">
						Återställ
					</BaseButton>
				</form>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'LostPassword',
	data: () => ({
		email: '',
	}),
	methods: {
		send() {
			console.log('Hello world!')
		},
	},
}
</script>

<style scoped>

</style>